import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject } from 'dcerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { CustomerConfig, CustomerDomainList, CustomerModuleKey, CustomerModules } from '../models/customer';
import { getStorageObject, setStorageObject } from '../utils/storage-manager';
import { ApiService } from './api.service';
import { DomainConfigService } from './domain-config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private domainConfigService = inject(DomainConfigService);

  path = '/customers';

  constructor() {
    this.path = this.apiService.getApiUrl() + this.path;

    this.getCustomerModules(true).subscribe();
  }

  /**
   * Get customers given a line
   */
  getCustomersWithLine(): Observable<CustomerDomainList> {
    const educaUniversityPath = this.apiService.getEducaUniversityApiUrl() + '/customer';

    return this.http.get<IJsonObject>(`${educaUniversityPath}/with-line/university`).pipe(
      map((jsonRequest) => {
        return Deserialize(jsonRequest, () => CustomerDomainList);
      })
    );
  }

  /**
   * Get current customer config
   */
  getCustomerConfig(): Observable<CustomerConfig> {
    const subdomain = this.domainConfigService.getConfigForSubdomain(this.apiService.getDomain()).subdomain;

    return this.http.get<IJsonObject>(`${this.path}/${subdomain}`).pipe(
      map((jsonRequest) => {
        return Deserialize(jsonRequest, () => CustomerConfig);
      })
    );
  }

  /**
   * Get current customer modules
   */
  getCustomerModules(updateStorage: boolean): Observable<CustomerModules> {
    const subdomain = this.domainConfigService.getConfigForSubdomain(this.apiService.getDomain()).subdomain;

    return this.http.get<IJsonObject>(`${this.path}/${subdomain}/modules`).pipe(
      map((jsonRequest) => {
        const data = Deserialize(jsonRequest, () => CustomerModules);
        if (updateStorage) {
          this.setCustomerModulesInLocalStorage(data);
        }

        return data;
      })
    );
  }

  setCustomerModulesInLocalStorage(modules: CustomerModules): void {
    const modulesEnabled = Object.keys(modules.modules).filter((moduleKey) => {
      return modules.modules[moduleKey as CustomerModuleKey].enabled;
    });

    setStorageObject(environment.enabledModulesStorage, modulesEnabled);
  }

  getCustomerModulesEnabledFromLocalStorage(): Array<CustomerModuleKey> {
    return getStorageObject(environment.enabledModulesStorage);
  }

  isCustomerModuleEnabled(moduleKey: CustomerModuleKey): boolean {
    const modules = this.getCustomerModulesEnabledFromLocalStorage();
    if (!modules) {
      return false;
    }

    return modules.includes(moduleKey);
  }
}
